var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "approve-permission" },
    [
      _vm.$store.state.menu.nowMenuList.indexOf("新增") >= 0
        ? _c(
            "div",
            { ref: "functionButtons", staticClass: "function-buttons" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onAddPromission }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "search-component" },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司名称：" } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              clearable: "",
                              placeholder: "请输入"
                            },
                            model: {
                              value: _vm.searchInfo.companyName,
                              callback: function($$v) {
                                _vm.$set(_vm.searchInfo, "companyName", $$v)
                              },
                              expression: "searchInfo.companyName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                size: "small",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchInfo.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchInfo, "status", $$v)
                                },
                                expression: "searchInfo.status"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 0, label: "有效" }
                              }),
                              _c("el-option", {
                                attrs: { value: 1, label: "失效" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.queryTableData }
                            },
                            [_vm._v("查询")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight } },
        [
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", width: "50px" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "企业名称",
              prop: "companyName",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "企业ID",
              prop: "wxEnterpriseId",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "审批模板ID",
              prop: "wxTemplateId",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "审批密钥",
              prop: "wxApprovalSecret",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "申请人",
              prop: "wxApplicant",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "申请人部门",
              prop: "department",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return _vm.$store.state.menu.nowMenuList.indexOf("更新") >=
                      0
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.editItem(scope.row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          scope.row.status == 0
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { color: "#f00" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.forbitItem(scope.row, 1)
                                    }
                                  }
                                },
                                [_vm._v("禁用")]
                              )
                            : _c(
                                "el-button",
                                {
                                  staticStyle: { color: "#14aa42" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.forbitItem(scope.row, 0)
                                    }
                                  }
                                },
                                [_vm._v("启用")]
                              )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.visible, width: "550px" },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("add-form", {
            ref: "addForm",
            attrs: { "address-hash": _vm.addressHash },
            on: {
              cancel: function($event) {
                _vm.visible = false
              },
              saveOk: function() {
                _vm.visible = false
                _vm.queryTableData()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }