import service from './request'
//接口前缀
const BASE_PREFIX = '/base';

// 审批权限管理

export function queryApproveCompanyList(data){
    return service({
        url:`/fortune/wx/config/queryWxConfigList`,
        method:'post',
        data
    })
}

// 新增
export function addNewApproveCompany(data){
    return service({
        url:`/fortune/wx/config/saveWxConfig`,
        method:'post',
        data
    })
}

// 更新 
export function updateApproveCompany(data){
    return service({
        url:'/fortune/wx/config/updateWxConfig',
        method:'put',
        data
    })
}

// 修改状态
export function updateCompanyStatus(data){
    return service({
        url:'/fortune/wx/config/updateWxConfigStatus',
        method:'put',
        data
    })
}