<template>
  <div class="add-form">
    <el-form ref="form" :model="ruleForm" :rules="rules" label-width="160px">
      <el-form-item label="企业名称：" prop="companyName">
        <!-- <el-input
          size="small"
          placeholder="请输入"
          v-model="ruleForm.companyName"
        ></el-input> -->
        <companySelect
          ref="companySelect"
          style="width: 100%"
          :clearable="true"
          :valueName="companyName"
          :value="companyId"
          :companyTree="companyList"
          :disabled="editTarget ? true : false"
          @getValue="getGroupId"
          @getName="getGroupName"
        />
      </el-form-item>
      <!-- <el-form-item label="所在城市：" prop="city">
        <el-cascader
          v-model="address"
          size="small"
          placeholder="请选择"
          style="width: 100%"
          :options="cityList"
          :disabled="editTarget ? true : false"
          @change="addressChange"
        ></el-cascader>
      </el-form-item> -->
      <el-form-item label="企业ID：" prop="wxEnterpriseId">
        <el-input
          v-model="ruleForm.wxEnterpriseId"
          size="small"
          placeholder="请输入"
          :disabled="editTarget ? true : false"
        ></el-input>
      </el-form-item>
      <el-form-item label="审批模板ID：" prop="wxTemplateId">
        <el-input
          v-model="ruleForm.wxTemplateId"
          size="small"
          placeholder="请输入"
          :disabled="editTarget ? true : false"
        ></el-input>
      </el-form-item>
      <el-form-item label="审批密钥：" prop="wxApprovalSecret">
        <el-input
          v-model="ruleForm.wxApprovalSecret"
          size="small"
          placeholder="请输入"
          :disabled="editTarget ? true : false"
        ></el-input>
      </el-form-item>
      <el-form-item label="申请人企业微信号：" prop="wxApplicant">
        <el-input
          size="small"
          placeholder="请输入"
          v-model="ruleForm.wxApplicant"
        ></el-input>
      </el-form-item>
      <el-form-item label="申请人部门：" prop="department">
        <el-input
          size="small"
          placeholder="请输入"
          v-model="ruleForm.department"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-select v-model="ruleForm.status" size="small" placeholder="请选择">
          <el-option :value="0" label="有效"></el-option>
          <el-option :value="1" label="失效"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="submit">确定</el-button>
        <el-button size="small" @click="$emit('cancel')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  addNewApproveCompany,
  updateApproveCompany,
} from "@/api/lib/approvePromission.js";

import { getBusinessCompanyTree, queryAreaList } from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
  },
  props: {
    addressHash: { type: Object, default: {} },
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        companyId: [
          { required: true, message: "公司不能为空", trigger: "blur" },
        ],
        // city: [
        //   { required: true, message: "所在城市不能为空", trigger: "change" },
        // ],
        wxTemplateId: [
          { required: true, message: "审批模板ID不能为空", trigger: "blur" },
        ],
        wxEnterpriseId: [
          { required: true, message: "企业ID不能为空", trigger: "blur" },
        ],
        wxApprovalSecret: [
          { required: true, message: "审批密钥不能为空", trigger: "blur" },
        ],
        wxApplicant: [
          { required: true, message: "企业微信号不能为空", trigger: "blur" },
        ],
        department: [
          { required: true, message: "部门不能为空", trigger: "blur" },
        ],
        status: [{ required: true, message: "部门不能为空", trigger: "blur" }],
      },
      address: [],
      region: [],
      companyList: [],
      cityList: [],
      companyName: "",
      companyId: null,
      editTarget: null,
    };
  },

  mounted() {
    this.getCompanyList();
    queryAreaList().then((res) => {
      if (res.code === 1000) {
        this.cityList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    });
  },
  methods: {
    addressChange(val) {
      if (val) {
        // this.ruleForm.city = val[val.length - 1];
        this.$set(this.ruleForm, "city", val[val.length - 1]);
      }
    },
    // 获取企业树
    getCompanyList() {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data;
        }
      });
    },

    editItem(row) {
      this.editTarget = row;
      this.ruleForm = { ...row };
      this.companyId = row.companyId;
      this.companyName = row.companyName;
      // let address = this.getFullAddresValue(row.city);
      // if (address.length == 3) address.pop();
      // this.$set(this, "address", address);
      this.$refs.form.clearValidate();
    },
    addNew() {
      this.ruleForm = {};
      this.editTarget = null;
      // this.$set(this, "address", []);
      this.$refs.form.clearValidate();
      this.companyId = null;
      this.companyName = null;
      this.$refs.companySelect ? this.$refs.companySelect.clearSelected() : "";
    },
    // 提交
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.editTarget) {
            updateApproveCompany(this.ruleForm).then((res) => {
              if (res.code == 1000) {
                this.$message.success("保存成功");
                this.$emit("saveOk");
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            addNewApproveCompany(this.ruleForm).then((res) => {
              if (res.code == 1000) {
                this.$message.success("保存成功");
                this.$emit("saveOk");
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
    // 业务公司
    getGroupId(v) {
      this.$set(this.ruleForm, "companyId", v);
    },
    getGroupName(name) {
      this.$set(this.ruleForm, "companyName", name);
    },
    //
    getFullAddresValue(code) {
      if (this.addressHash[code] && this.addressHash[code].parent) {
        return this.getFullAddresValue(
          this.addressHash[code].parent.value
        ).concat([code - 0]);
      } else {
        return [code - 0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-form {
  padding-right: 50px;
}
</style>