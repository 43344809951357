<template>
  <div class="approve-permission">
    <!-- 审核权限管理 -->
    <div
      class="function-buttons"
      ref="functionButtons"
      v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
    >
      <el-button type="primary" size="small" @click="onAddPromission"
        >新增</el-button
      >
    </div>
    <div class="search-component">
      <el-form label-width="100px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="公司名称：">
              <el-input
                v-model="searchInfo.companyName"
                size="small"
                clearable
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态：">
              <el-select
                v-model="searchInfo.status"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option :value="0" label="有效"></el-option>
                <el-option :value="1" label="失效"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="0">
              <el-button
                style="margin-left: 10px"
                size="small"
                type="primary"
                @click="queryTableData"
                >查询</el-button
              >
            </el-form-item>
            <!-- <el-button size="small">清空</el-button> -->
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column label="序号" type="index" width="50px"></el-table-column>
      <el-table-column
        label="企业名称"
        prop="companyName"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <!-- <el-table-column
        label="所在城市"
        prop="city"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="{ row }">
          {{ getFullAddress(row.city) }}
        </template>
      </el-table-column> -->
      <el-table-column
        label="企业ID"
        prop="wxEnterpriseId"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="审批模板ID"
        prop="wxTemplateId"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="审批密钥"
        prop="wxApprovalSecret"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <!-- <el-table-column
        label="审批人企业微信号"
        prop="wxApplicant"
        :show-overflow-tooltip="true"
      ></el-table-column> -->
      <el-table-column
        label="申请人"
        prop="wxApplicant"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="申请人部门"
        prop="department"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="操作">
        <template
          slot-scope="scope"
          v-if="$store.state.menu.nowMenuList.indexOf('更新') >= 0"
        >
          <el-button type="text" @click="editItem(scope.row)">编辑</el-button>
          <el-button
            v-if="scope.row.status == 0"
            type="text"
            style="color: #f00"
            @click="forbitItem(scope.row, 1)"
            >禁用</el-button
          >
          <el-button
            v-else
            type="text"
            style="color: #14aa42"
            @click="forbitItem(scope.row, 0)"
            >启用</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.currentPage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="pageInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      ></el-pagination>
    </div> -->
    <el-dialog :title="title" :visible.sync="visible" width="550px">
      <add-form
        ref="addForm"
        :address-hash="addressHash"
        @cancel="visible = false"
        @saveOk="
          () => {
            visible = false;
            queryTableData();
          }
        "
      />
    </el-dialog>
  </div>
</template>

<script>
import addForm from "./addForm.vue";
import {
  queryApproveCompanyList,
  updateCompanyStatus,
} from "@/api/lib/approvePromission.js";
import { regionData } from "element-china-area-data";
export default {
  components: {
    addForm,
  },
  data() {
    return {
      tableHeight: `${window.innerHeight - 250}px`,
      tableData: [],
      visible: false,
      addressHash: {},
      searchInfo: {},
      title: "新增企业权限",
      //   pageInfo: {
      //     total: 0,
      //     size: 10,
      //     currentPage: 1,
      //   },
    };
  },
  beforeMount() {
    this.flatTree(regionData);
  },
  mounted() {
    this.queryTableData();
  },
  methods: {
    // 添加审批权限
    onAddPromission() {
      this.visible = true;
      this.title = "新增企业权限";
      this.$nextTick(() => {
        this.$refs.addForm.addNew();
      });
    },
    // 请求分页数据
    queryTableData() {
      queryApproveCompanyList({
        ...this.searchInfo,
      }).then((res) => {
        if (res.data) {
          this.tableData = res.data;
        }
      });
    },
    // 编辑
    editItem(row) {
      this.visible = true;
      this.title = "编辑企业权限";
      this.$nextTick(() => {
        this.$refs.addForm.editItem(row);
      });
    },
    // 禁用
    forbitItem(row, status) {
      const text =
        status == 1
          ? "该企业将不再受理ITS上发起的审批申请"
          : "该企业将接收ITS上发起的审批申请";
      this.$confirm(text, status == 1 ? "禁用" : "启用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateCompanyStatus({ id: row.id, status }).then((res) => {
            if (res.code === 1000) {
              this.$message.success("修改成功");
              this.queryTableData();
            }
          });
        })
        .catch(() => {});
    },
    // 遍历regionData
    flatTree(arr, parent) {
      arr.forEach((elem) => {
        elem.parent = parent ?? null;
        this.addressHash[elem.value] = elem;
        if (elem.children && elem.children.length) {
          this.flatTree(elem.children, elem);
        }
      });
    },
    // 获取完整地址
    getFullAddress(address) {
      let reg = this.addressHash[address];
      if (!reg) return "";
      return (
        (reg.parent ? this.getFullAddress(reg.parent.value) + "/" : "") +
        reg.label
      );
    },
    // 翻页
    // handleSizeChange(size) {
    //   this.pageInfo.size = size;
    //   this.queryTableData();
    // },
    // handleCurrentChange(page) {
    //   this.pageInfo.currentPage = page;
    //   this.queryTableData();
    // },
  },
};
</script>

<style lang="scss">
.approve-permission {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px 3px #ddd;
  border-radius: 5px;
  .search-component {
    background: #eee;
    padding: 15px 0;
    .el-form-item {
      margin: 0;
    }
  }
}
</style>