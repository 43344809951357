var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-form" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "企业名称：", prop: "companyName" } },
            [
              _c("companySelect", {
                ref: "companySelect",
                staticStyle: { width: "100%" },
                attrs: {
                  clearable: true,
                  valueName: _vm.companyName,
                  value: _vm.companyId,
                  companyTree: _vm.companyList,
                  disabled: _vm.editTarget ? true : false
                },
                on: { getValue: _vm.getGroupId, getName: _vm.getGroupName }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "企业ID：", prop: "wxEnterpriseId" } },
            [
              _c("el-input", {
                attrs: {
                  size: "small",
                  placeholder: "请输入",
                  disabled: _vm.editTarget ? true : false
                },
                model: {
                  value: _vm.ruleForm.wxEnterpriseId,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "wxEnterpriseId", $$v)
                  },
                  expression: "ruleForm.wxEnterpriseId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审批模板ID：", prop: "wxTemplateId" } },
            [
              _c("el-input", {
                attrs: {
                  size: "small",
                  placeholder: "请输入",
                  disabled: _vm.editTarget ? true : false
                },
                model: {
                  value: _vm.ruleForm.wxTemplateId,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "wxTemplateId", $$v)
                  },
                  expression: "ruleForm.wxTemplateId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审批密钥：", prop: "wxApprovalSecret" } },
            [
              _c("el-input", {
                attrs: {
                  size: "small",
                  placeholder: "请输入",
                  disabled: _vm.editTarget ? true : false
                },
                model: {
                  value: _vm.ruleForm.wxApprovalSecret,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "wxApprovalSecret", $$v)
                  },
                  expression: "ruleForm.wxApprovalSecret"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "申请人企业微信号：", prop: "wxApplicant" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.wxApplicant,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "wxApplicant", $$v)
                  },
                  expression: "ruleForm.wxApplicant"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "申请人部门：", prop: "department" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.ruleForm.department,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "department", $$v)
                  },
                  expression: "ruleForm.department"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "small", placeholder: "请选择" },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status"
                  }
                },
                [
                  _c("el-option", { attrs: { value: 0, label: "有效" } }),
                  _c("el-option", { attrs: { value: 1, label: "失效" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit }
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }